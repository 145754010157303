import React from 'react'
import { node, oneOfType, arrayOf } from 'prop-types'
import { useLocation } from 'react-router-dom'
import Nav from '../nav'

const Layout = ({ children }) => {
  const location = useLocation()
  return (
    <div id="app" className="is-marginless">
      <Nav />

      <div className="container" id="hukka-content">
        <img id="hukka-logo" src="/img/hukka_logo_slogan.png" alt="Hukka logo" />

        {children}
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
}

export default Layout
