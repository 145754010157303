/* eslint-disable no-param-reassign */
import produce from 'immer'
import merge from 'lodash/merge'
import {
  LOGIN_REQUEST_SUCCESS,
  TOKEN_LOGIN,
  HYDRATE_USER,
  LOGOUT
} from '../actions'

export const initialState = {
  user: {
    loggedIn: false,
    token: null
  }
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  return produce(state, draft => {
    switch (action.type) {
      case LOGOUT:
        draft = initialState
        break

      case LOGIN_REQUEST_SUCCESS: {
        const { userInfo, token } = payload

        draft.user = { ...userInfo, loggedIn: true, token }
        break
      }

      case TOKEN_LOGIN: {
        const { token } = payload

        draft.user = { token, loggedIn: true, rou: 1 }
        break
      }
      
      case HYDRATE_USER:
        draft.user = merge(draft.user, payload)
        break

      default:
        return draft
    }

    return draft
  })
}
