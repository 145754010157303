import React from 'react'
import { useSelector } from 'react-redux'

import Login from '../login'
import Dashboard from './dashboard'

const DashboardPage = () => {
  const { loggedIn, isStaff } = useSelector(state => state.user)

  const renderComponent = (loggedIn && isStaff) ? <Dashboard /> : <Login />

  return renderComponent
}

export default DashboardPage
