import React, { useState, useEffect } from 'react'

import {
  get,
  HUKKASERVICES_URL
} from '../../utility/api'

import Services from './services'
import Subscriptions from './subscriptions'

const ServicesIndex = () => {
	const [selectedTab, setSelectedTab] = useState('services')
	const [services, setServices] = useState([])
	const [init, setInit] = useState(false)

	const getServices = async (from, to) => {
    const resp = await get(HUKKASERVICES_URL)

    if (resp.status === 200) {
    	setServices(resp.data)
    } else {
    }
  }

  useEffect(() => {
  	if (!init) {
  		setInit(true)
  		getServices()
  	}
  })
  
  return (<div>
  	<div>
	  	<button
	      type="button"
	      className={'button is-small' + (selectedTab === 'services' ? ' is-primary-background' : '')}
	      style={{margin: '5px'}}
	      onClick={() => {
	        setSelectedTab('services')
	      }}
	    >
	      Services
	    </button>
	    <button
	      type="button"
	      className={'button is-small' + (selectedTab === 'subscriptions' ? ' is-primary-background' : '')}
	      style={{margin: '5px'}}
	      onClick={() => {
	        setSelectedTab('subscriptions')
	      }}
	    >
	      Subscriptions
	    </button>
  	</div>
  	<div>
  	{ selectedTab === 'services' && (<Services services={services} />) }
  	{ selectedTab === 'subscriptions' && (<Subscriptions services={services} />) }
  	</div>
  </div>)
}

export default ServicesIndex
