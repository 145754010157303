import React, { useState, useEffect } from 'react'
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from '../modal'

import {
  get,
  post,
  patch,
  ORGS_URL,
  RESTAURANTS_URL,
  HUKKASERVICESUBSCRIPTIONS_URL
} from '../../utility/api'

import HukkaSelect from '../hukkaSelect'

const Subscriptions = ({services}) => {
	const [orgs, setOrgs] = useState([])
	const [selectedOrg, setSelectedOrg] = useState(null)

	const [restaurants, setRestaurants] = useState([])
	const [serviceSubscriptions, setServiceSubscriptions] = useState([])
	const [init, setInit] = useState(false)

  const [showBulkModal, setShowBulkModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)
	const [isAdd, setIsAdd] = useState(false)
  const [edit, setEdit] = useState(null)

  const [bulkSelectedRestaurants, setBulkSelectedRestaurants] = useState([])
  const [bulkSelectedServices, setBulkSelectedServices] = useState([])
  const [bulkDate, setBulkDate] = useState(new Date())

	const getOrgs = async () => {
    let payload = {}
    const resp = await get(ORGS_URL, payload)

    if (resp.status === 200) {
    	setOrgs(resp.data.slice()
		  .sort((a, b) => {
		    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
		    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
		    return 0
		  }).map(ms => ({
	      value: ms.id,
	      label: ms.name
	    })))
    } else {
    }
  }

	const getRestaurants = async (org) => {
    let payload = {
    	org: org
    }
    const resp = await get(RESTAURANTS_URL, payload)

    if (resp.status === 200) {
    	setRestaurants(resp.data.slice()
		  .sort((a, b) => {
		    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
		    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
		    return 0
		  }))
    } else {
    }
  }

	const getServiceSubscriptions = async (org) => {
    let payload = {
    	org: org
    }
    const resp = await get(HUKKASERVICESUBSCRIPTIONS_URL, payload)

    if (resp.status === 200) {
    	setServiceSubscriptions(resp.data)
    } else {
    }
  }

  const editCreateServiceSubscription = async () => {
    let payload = {
      restaurant: edit.restaurant,
      service: edit.service,
      valid_until: moment(edit.validUntil)
    }
    const resp = await post(HUKKASERVICESUBSCRIPTIONS_URL, payload)

    if (resp.status === 201) {
      getServiceSubscriptions(selectedOrg.value)
    } else {
    }
    setShowEditModal(false)
    setEdit(null)
    setIsAdd(false)
  }

  const editPatchServiceSubscription = async () => {
    let payload = {
      valid_until: moment(edit.validUntil)
    }
    const resp = await patch(HUKKASERVICESUBSCRIPTIONS_URL, edit.service, payload)

    if (resp.status === 200) {
      getServiceSubscriptions(selectedOrg.value)
    } else {
    }
    setShowEditModal(false)
    setEdit(null)
  }

  const createServiceSubscription = async (res, ser, valid) => {
    let payload = {
      restaurant: res,
      service: ser,
      valid_until: moment(valid)
    }
    const resp = await post(HUKKASERVICESUBSCRIPTIONS_URL, payload)

    if (resp.status === 201) {
      getServiceSubscriptions(selectedOrg.value)
    } else {
    }
    setShowBulkModal(false)
  }

  const patchServiceSubscription = async (ser, valid) => {
    let payload = {
      valid_until: moment(valid)
    }
    const resp = await patch(HUKKASERVICESUBSCRIPTIONS_URL, ser, payload)

    if (resp.status === 200) {
      getServiceSubscriptions(selectedOrg.value)
    } else {
    }
    setShowBulkModal(false)
  }

  const bulkSetServiceSubscription = async () => {
    let createArr = []
    let patchArr = []
    bulkSelectedRestaurants.forEach(function (r) {
      bulkSelectedServices.forEach(function (s) {
        let sub = serviceSubscriptions.find(ss => ss.restaurant === r && ss.service === s)
        if (sub) {
          patchArr.push({id: sub.id, bulkDate})
        } else {
          createArr.push({r, s, bulkDate})
        }
      })
    })

    Promise.all(createArr.map(arr => createServiceSubscription(arr.r, arr.s, arr.bulkDate))).then((values) => {
      getServiceSubscriptions(selectedOrg.value)
    })

    Promise.all(patchArr.map(arr => patchServiceSubscription(arr.id, arr.bulkDate))).then((values) => {
      getServiceSubscriptions(selectedOrg.value)
    })
  }

  useEffect(() => {
  	if (!init) {
  		setInit(true)
  		getOrgs()
  	}
  })

  const subscriptionsRows = () => {
  	let html = []
  	
  	let restaurantsHtml = []

  	restaurants.forEach(function (r) {
  		let restaurantRow = []
      restaurantRow.push(<td key={r.id+'checkbox'+Math.random()} style={{width: '30px'}}>
        <input
          type="checkbox"
          defaultChecked={restaurants.length > 0 && bulkSelectedRestaurants.indexOf(r.id) !== -1}
          onChange={() => {
            if (bulkSelectedRestaurants.indexOf(r.id) !== -1) {
              let arr = bulkSelectedRestaurants
              arr.splice(bulkSelectedRestaurants.indexOf(r.id), 1)
              setBulkSelectedRestaurants(arr)
            } else {
              let arr = bulkSelectedRestaurants
              arr.push(r.id)
              setBulkSelectedRestaurants(arr)
            }
          }}
        />
      </td>)
  		restaurantRow.push(<td key={r.id} style={{textAlign: 'left'}}>{r.name}</td>)
  		services.forEach(function (s) {
  			let serviceObj = serviceSubscriptions.find(ss => ss.service === s.id && ss.restaurant === r.id)
  			if (serviceObj) {
          restaurantRow.push(<td
    				key={r.id+s.id}
    				style={{cursor: 'pointer', textAlign: 'center'}}
    				onClick={() => {
    					setEdit({
                service: serviceObj.id,
    						restaurantName: r.name,
    						serviceName: s.name,
    						validUntil: moment(serviceObj.validUntil).format('YYYY-MM-DD')
    					})
              setShowEditModal(true)
    				}}
    			>
    				{moment().isBefore(serviceObj.validUntil) ? (<span>{moment(serviceObj.validUntil).format('YYYY-MM-DD')}</span>) : (<span style={{color: 'red'}}>{moment(serviceObj.validUntil).format('YYYY-MM-DD')}</span>)}
    			</td>)
        } else {
          restaurantRow.push(<td
            key={r.id+s.id}
            style={{cursor: 'pointer', textAlign: 'center'}}
            onClick={() => {
              setIsAdd(true)
              setEdit({
                service: s.id,
                restaurant: r.id,
                restaurantName: r.name,
                serviceName: s.name,
                validUntil: moment().format('YYYY-MM-DD')
              })
              setShowEditModal(true)
            }}
          >
            <span>-</span>
          </td>)
        }
  		})
  		restaurantsHtml.push(<tr key={r.id+'row'} className="hover-border">{restaurantRow}</tr>)
  	})

  	let headers = []
    headers.push(<th style={{width: '30px'}} key={'headercheckbox'+Math.random()}>
      <input
        type="checkbox"
        defaultChecked={restaurants.length > 0 && bulkSelectedRestaurants.length === restaurants.length}
        onChange={() => {
          if (bulkSelectedRestaurants.length === restaurants.length) {
            setBulkSelectedRestaurants([])
          } else {
            let restaurantIds = restaurants.map(r => r.id)
            setBulkSelectedRestaurants(restaurantIds)
          }
        }}
      />
    </th>)
  	headers.push(<th key={'headerrestaurants'} style={{textAlign: 'left'}}>
      <br />
      Restaurant
    </th>)
  	services.forEach(function (s) {
			headers.push(<th key={'header'+s.id} style={{textAlign: 'center'}} key={s.id+Math.random()}>
        <input
          name={s.id+'checkbox'}
          type="checkbox"
          defaultChecked={services.length > 0 && bulkSelectedServices.indexOf(s.id) !== -1}
          onChange={() => {
            if (bulkSelectedServices.indexOf(s.id) !== -1) {
              let arr = bulkSelectedServices
              arr.splice(bulkSelectedServices.indexOf(s.id), 1)
              setBulkSelectedServices(arr)
            } else {
              let arr = bulkSelectedServices
              arr.push(s.id)
              setBulkSelectedServices(arr)
            }
          }}
        />
        <br />
        {s.name}
      </th>)
		})

  	html.push(<tr>{headers}</tr>)

  	html.push(restaurantsHtml)

  	return html
  }


  const editModalHtml = (
    <Modal
      isActive={showEditModal}
      title="Edit service sub"
      submitCallback={() => {
        if (isAdd) {
          editCreateServiceSubscription()
        } else {
          editPatchServiceSubscription()
        }
      }}
      closeModalCallBack={() => {
        setShowEditModal(false)
        setEdit(null)
      }}
    >
      <div>
      	{ edit && (<>
  	      <h1
  	        className="title is-4 is-size-4-mobile is-uppercase"
  	        style={{lineHeight: '1'}}
  	      >
  	        {edit.restaurantName}
  	      </h1>
  	      <h2
            className="title is-4 is-size-4-mobile is-uppercase"
            style={{lineHeight: '1'}}
          >
            {edit.serviceName}
          </h2>

          <DatePicker
            selected={new Date(moment(edit.validUntil))}
            dateFormat="yyyy-MM-dd"
            onChange={e => {
              let editObj = JSON.parse(JSON.stringify(edit))
              editObj['validUntil'] = moment(e).format('YYYY-MM-DD')
              setEdit(editObj)
            }}
          />
      	</>)}
      </div>
    </Modal>
  )


  const bulkModalHtml = (
    <Modal
      isActive={showBulkModal}
      title="Set multiple subscriptions"
      submitCallback={() => {
        bulkSetServiceSubscription()
      }}
      closeModalCallBack={() => {
        setShowBulkModal(false)
      }}
      hideModalSubmit={bulkSelectedRestaurants.length === 0 || bulkSelectedServices.length === 0}
    >
      <div>
        { (bulkSelectedRestaurants.length === 0 || bulkSelectedServices.length === 0) && (
          <h1
            className="title is-4 is-size-4-mobile is-uppercase"
            style={{lineHeight: '1'}}
          >
            At least one restaurant and at least one service must be selected
          </h1>
        )}
        { bulkSelectedRestaurants.length > 0 && bulkSelectedServices.length > 0 && (<>
          <h1
            className="title is-4 is-size-4-mobile is-uppercase"
            style={{lineHeight: '1'}}
          >
            {bulkSelectedRestaurants.length} restaurants chosen
          </h1>
          <h2
            className="title is-4 is-size-4-mobile is-uppercase"
            style={{lineHeight: '1'}}
          >
            {bulkSelectedServices.length} services chosen
          </h2>

          <h3
            className="title is-5 is-size-5-mobile is-uppercase"
            style={{lineHeight: '1', margin: '0', padding: '0'}}
          >
            Set date:
          </h3>
          <DatePicker
            selected={new Date(moment(bulkDate))}
            dateFormat="yyyy-MM-dd"
            onChange={e => {
              setBulkDate(moment(e).format('YYYY-MM-DD'))
            }}
          />
        </>)}
      </div>
    </Modal>
  )

  
  return (<div>
  	<h1 className="title is-1 is-size-3-mobile is-uppercase">Subscriptions</h1>

  	<div className="select is-large is-fullwidth hukkaselect">
      <HukkaSelect
        options={orgs}
        placeholder={'Choose organization'}
        value={selectedOrg}
        onChange={e => {
        	setSelectedOrg(e)
        	getRestaurants(e.value)
        	getServiceSubscriptions(e.value)
          setBulkSelectedRestaurants([])
          setBulkSelectedServices([])
        }}
      />
    </div>

    { restaurants.length > 0 && serviceSubscriptions.length > 0 && (<div>
      <input
        type="button"
        value="Set date for all selected"
        style={{margin: '0 5px'}}
        onClick={() => {
          setShowBulkModal(true)
        }}
      />
      <br /><br />
      <input
        type="button"
        value="Select all"
        style={{margin: '0 5px'}}
        onClick={() => {
          setBulkSelectedRestaurants(restaurants.map(r => r.id))
          setBulkSelectedServices(services.map(s => s.id))
        }}
      />
      <input
        type="button"
        value="Clear all"
        style={{margin: '0 5px'}}
        onClick={() => {
          setBulkSelectedRestaurants([])
          setBulkSelectedServices([])
        }}
      />
      <br /><br />
    	<table style={{ width: '100%', tableLayout: 'fixed', overflowWrap: 'break-word' }}>
    		{ subscriptionsRows() }
    	</table>
    </div>)}

    {editModalHtml}
    {bulkModalHtml}
  </div>)
}

export default Subscriptions
