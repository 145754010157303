import React from 'react'
import { bool, element, arrayOf, string, oneOfType, func } from 'prop-types'
import ErrorMessage from '../errorMessage'

const Modal = ({
  isActive,
  children,
  title,
  submitCallback,
  errors,
  closeModalCallBack,
  okButtonOnly = false,
  hideModalSubmit = false,
}) => {
  return (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card" style={{ minHeight: '95%' }}>
        <header className="modal-card-head">
          <p className="modal-card-title is-uppercase">{title}</p>
        </header>

        <section className="modal-card-body">{children}</section>

        <footer className="modal-card-foot">
          <ErrorMessage msg={errors} />

          {okButtonOnly && (
            <button
              type="button"
              className="button is-success is-large"
              onClick={closeModalCallBack}
            >
              Ok
            </button>
          )}
          {!okButtonOnly && (<>
            {!hideModalSubmit && (
              <button
                type="button"
                className="button is-success is-large"
                onClick={submitCallback}
              >
                Tallenna
              </button>
            )}
            <button
              type="button"
              className="button is-large"
              onClick={closeModalCallBack}
            >
              Peruuta
            </button>
          </>)}
        </footer>
      </div>
    </div>
  )
}

Modal.Field = ({ label, children, errors }) => {
  return (
    <div className="field">
      <div className="label is-uppercase" style={{ textAlign: 'left' }}>
        {label}
      </div>
      <div className="control">{children}</div>
      <ErrorMessage msg={errors} />
    </div>
  )
}

Modal.Field.propTypes = {
  label: string.isRequired,
  children: element.isRequired,
  errors: arrayOf(string),
}

Modal.Field.defaultProps = {
  errors: null,
}

Modal.defaultProps = {
  errors: null,
  submitCallback: null,
  hideModalSubmit: null,
}

Modal.propTypes = {
  isActive: bool.isRequired,
  children: oneOfType([arrayOf(element), element]).isRequired,
  title: string.isRequired,
  closeModalCallBack: func.isRequired,
  hideModalSubmit: bool,
  submitCallback: func,
  errors: arrayOf(string),
}

export default Modal
