import React from 'react'

const Services = () => {
	//const {} = useSelector(state => state)
  
  return (<div>
  	<h1 className="title is-1 is-size-3-mobile is-uppercase">Services</h1>
  </div>)
}

export default Services
