import React from 'react'
import './sass/index.scss'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import PrivateRoute, { SCOPES } from './privateRoute'
import store from './reducers/createStore'

import Layout from './components/layout'

import Dashboard from './components/dashboard'
import Services from './components/services'

const App = () => {
  const { STAFF_ADMIN } = SCOPES
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Layout>
            <Route exact path="/">
              <Dashboard />
            </Route>

            <PrivateRoute scopes={[STAFF_ADMIN]} exact path="/services">
              <Services />
            </PrivateRoute>
          </Layout>
        </Switch>
      </Router>
    </Provider>
  )
}

export default App
