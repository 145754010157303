import {
  get,
  HUKKAPROFILES_URL,
  HUKKASERVICES_URL,
  HUKKASERVICESUBSCRIPTIONS_URL,
} from '../utility/api'
import moment from 'moment'

export const HYDRATE_USER = 'HYDRATE_USER'
export const hydrateUser = user => ({
  type: HYDRATE_USER,
  payload: user
})

export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS'
export const loginRequestSuccess = respData => dispatch => {
  dispatch({
    type: LOGIN_REQUEST_SUCCESS,
    payload: { userInfo: respData.data, token: respData.token },
  })
}

export const TOKEN_LOGIN = 'TOKEN_LOGIN'
export const tokenLogin = token => dispatch => {
  dispatch({
    type: TOKEN_LOGIN,
    payload: { token },
  })
}

export const LOGOUT = 'LOGOUT'
export const logout = () => ({
  type: LOGOUT,
})
