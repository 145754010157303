/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../../actions'

const Nav = () => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const [langOpen, setLangOpen] = useState(false)
  const { loggedIn, isStaff, orgAdmin, rou:readOnlyUser } = useSelector(state => state.user) || { loggedIn: false, isStaff: false, orgAdmin: false, readOnlyUser: false }
  const dispatch = useDispatch()

  return (
    <div>
      {loggedIn && (
      <nav
        className="mainNav"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="mainNav__burger">
          <a
            role="button"
            className="mainNav__burger__link"
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setMobileOpen(!mobileOpen)}
            onKeyPress={() => setMobileOpen(!mobileOpen)}
            tabIndex={0}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div
          className={'mainNav__items' + (mobileOpen ? ' mainNav__items--open' : '')}
        >
            <Link
              className="mainNav__item"
              to="/"
              onClick={() => setMobileOpen(false)}
            >
              <span className="icon">
                Home
                <i className="fas fa-home fa-lg" />
              </span>
            </Link>

            <Link
              className="mainNav__item"
              to="/services"
              onClick={() => setMobileOpen(false)}
            >
              <span>
                Services
              </span>
            </Link>
        </div>
          <Link
            className="mainNav__item"
            onClick={() => {
              dispatch(logout())
              setMobileOpen(false)
              window.location.href = '/'
            }}
            to="/"
          >
            Kirjaudu ulos
          </Link>
      </nav>
      )}
    </div>
  )
}

export default Nav