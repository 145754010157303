import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { } from '../../utility/api'
import { } from '../../actions'
import moment from 'moment'

const Dashboard = () => {

  return (
    <div className="addons">
      <div>
        <h1 className="title is-1 is-size-3-mobile is-uppercase">Dashboard</h1>
      </div>
    </div>
  )
}

export default Dashboard
